import React from "react";

//<Pagination currentPage={currentPage} itemsPerPage={itemsPerPage}
// length={customers.length} onPageChange={handleChangeCurrentPage} />

const range = (start, end) => {
    return [...Array(end - start).keys()].map((el) => el + start);
}

const getPagesCut = ({pageCount, pagesCutCount, currentPage}) => {
    const ceiling = Math.ceil(pagesCutCount / 2);
    const floor = Math.floor(pagesCutCount / 2);

    if (pageCount < pagesCutCount){
        return {start:1, end: pageCount + 1};
    }else if(currentPage >= 1 && currentPage <= ceiling){
        return {start:1, end: pagesCutCount + 1};
    }else if(currentPage + floor  >= pageCount){
        return {start : pageCount - pagesCutCount + 1, end: pageCount + 1};
    }else{
        return {start : currentPage - ceiling + 1, end: currentPage + floor + 1};
    }
};

const Pagination = ({ currentPage, itemsPerPage, length, onPageChange}) => {

    const pageCount = Math.ceil(length / itemsPerPage);
    /*for(let i = 1; i <= pageCount; i++){
        pages.push(i);
    }*/

    const pagesCut = getPagesCut({pageCount, pagesCutCount:5, currentPage});
    const pages = range(pagesCut.start, pagesCut.end);
    return ( <>
        <div>
            <ul className="pagination pagination-sm">
                <li className={"page-item "+ (currentPage === 1 && "disabled")}>
                    <button className="page-link" onClick={() => onPageChange(1)}>Prémière</button>
                </li>
                <li className={"page-item "+ (currentPage === 1 && "disabled")}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>&laquo;</button>
                </li>

                {pages.map(page => <li className={"page-item " + (currentPage === page && "active")} key={page}>
                    <button className="page-link" onClick={() => onPageChange(page)}>{page}</button>
                </li>)}

                <li className={"page-item "+ (currentPage === pageCount && "disabled")}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>&raquo;</button>
                </li>
                <li className={"page-item "+ (currentPage === pageCount && "disabled")}>
                    <button className="page-link" onClick={() => onPageChange(pageCount)}>Dernière</button>
                </li>
            </ul>
        </div>
    </> );
};


Pagination.getData = (items, currentPage, itemsPerPage) => {
    const start = (currentPage * itemsPerPage) - itemsPerPage;
    return items.slice(start, start + itemsPerPage);

};

export default Pagination;