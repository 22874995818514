import axios from "axios";
import {SETTING_API} from "../../config";
import Cache from "../cache";



async function findSetting() {
    const cachedAdminSetting = await Cache.get("AdminSetting");
    if(cachedAdminSetting) return cachedAdminSetting;
    return axios
        .get(SETTING_API)
        .then(response => {
            const setting = response.data.setting;
            Cache.set("AdminSetting", setting);
            return setting;
        });
}



async function find(id){

    const cachedSetting = await Cache.get("AdminSetting." + id);
    if(cachedSetting) return cachedSetting;
    return axios.get(SETTING_API + "/" + id)
        .then(response => {
            const setting = response.data.setting;
            Cache.set("AdminSetting." + id, setting);
            return setting;
        });
}

async function deleteData(){
    return axios.get(SETTING_API + "/deleteData")
        .then(response => {
            const setting = response.data.setting;
            return setting;
        });
}


function updateSetting(id, setting){

    return axios.post(SETTING_API + "/store", {...setting, id: id})
        .then(async response => {

            const cachedSetting = await Cache.get("AdminSetting." + id);
            const cachedSet = await Cache.get("AdminSetting." + id);

            if(cachedSet){
                Cache.set("AdminSetting." + id, response.data.setting);
            }

            if(cachedSetting){
                const index = cachedSetting.findIndex(b => b.id === +id);
                cachedSetting[index] = response.data.setting;
            }

            return response;
        });
}


function create(setting){

    return axios.post(SETTING_API + "/store", setting)
        .then(async response => {
            const cachedSetting = await Cache.get("AdminSetting");

            if(cachedSetting){
                Cache.set("AdminSetting", [...cachedSetting, response.data.settingHour]);
            }

            return response;
        });
}



export default {
    find,
    findSetting,
    create,
    deleteData,
    updateSetting
};