import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import SettingAPI from "../../../apis/adminApi/settingAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import { toast } from "react-toastify";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import CheckBoxField from "../../forms/CheckBoxField";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";

const SettingIndex = () => {

    const [showLoader, setShowLoader] = useState(false);

    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(false);

    const [setting, setSetting] = useState({
        company_name : "", company_idnat : "", company_rccm  : "", company_impot  : "",
        company_address : "", company_contact : "", company_email : "",
        company_bank_detail : "", price_per_kilo : "", currency : "", id : "", updateTicketPerm : 0, perm_date : ""
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        company_name : "", company_idnat : "", company_rccm  : "", company_impot  : "",
        company_address : "", company_contact : "", company_email : "",
        company_bank_detail : "", price_per_kilo : "", currency : ""
    });

    const fetchSetting = async () => {
        try{
            const { company_name, company_address, company_rccm,company_idnat,company_impot,company_contact,company_email,
                company_bank_detail, price_per_kilo, currency, id, updateTicketPerm, perm_date} = await SettingAPI.findSetting();
            setSetting({ company_name, company_address, company_contact,company_email,
                company_bank_detail,company_rccm,company_idnat,company_impot, price_per_kilo, currency, id,
                updateTicketPerm, perm_date });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger la facture démandée !");
            navigate("/admin");
        }
    };


    const handleChex = () => {
        setChecked(!checked);
        if (!checked === true) {
            setSetting({...setting, updateTicketPerm : 1});
        }else{
            setSetting({...setting, updateTicketPerm : 0});
        }

    };


    useEffect(() => {
        fetchSetting();
    }, []);


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setSetting({...setting, [name] : value})
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await SettingAPI.updateSetting(setting.id, setting);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Paramettre modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
           if (response) {
               const {violations} = response.data;

               if(violations){
                   const apiErrors = {};
                   Object.keys(violations).forEach(key => {
                       apiErrors[key] = violations[key]
                   });

                   setErrors(apiErrors);
                   toast.error("Une erreur est servenue dans le formulaire de la modification du bus !");
               }
           }
        }
    };


    const  handleDelteDatas = async () => {

        try{

            setShowLoader(true);
            await SettingAPI.deleteData();
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Les données ont été supprimées avec succès");
            window.location.reload();
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {message} = response.data;
            toast.error(message);
        }
    };





    return (
        <>
            <div className="pagetitle">
                <h1>Les Paramettres</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/admin">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Les Paramettres</li>
                    </ol>
                </nav>
            </div>

            <section className="section">
                <div className="container">
                    {loading && <FormContentLoader />}
                    {!loading &&  <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Identité de l'entreprise</h5>

                                                <Field
                                                    name="company_name"
                                                    error={errors.company_name}
                                                    value={setting.company_name}
                                                    onChange={handleChange}
                                                    label="Nom de l'entreprise"
                                                    placeholder="Nom de l'entreprise"
                                                />

                                                <Field
                                                    name="company_address"
                                                    error={errors.company_address}
                                                    value={setting.company_address}
                                                    onChange={handleChange}
                                                    label="Adresse de l'entreprise"
                                                    placeholder="Adresse de l'entreprise"
                                                />

                                                <Field
                                                    name="company_bank_detail"
                                                    error={errors.company_bank_detail}
                                                    value={setting.company_bank_detail}
                                                    onChange={handleChange}
                                                    label="Numéro compte bancaire"
                                                    placeholder="Numéro compte bancaire"
                                                />

                                                <Field
                                                    name="company_idnat"
                                                    error={errors.company_idnat}
                                                    value={setting.company_idnat}
                                                    onChange={handleChange}
                                                    label="Id Nat"
                                                    placeholder="Id Nat"
                                                />

                                                <Field
                                                    name="company_rccm"
                                                    error={errors.company_rccm}
                                                    value={setting.company_rccm}
                                                    onChange={handleChange}
                                                    label="RCCM"
                                                    placeholder="RCCM"
                                                />

                                                <Field
                                                    name="company_impot"
                                                    error={errors.company_impot}
                                                    value={setting.company_impot}
                                                    onChange={handleChange}
                                                    label="Numéro impot"
                                                    placeholder="Numéro impot"
                                                />

                                                <MyButton
                                                    text="Modifier"
                                                    onSubmit={handleSubmit}
                                                    loading={showLoader}
                                                    disabled={showLoader}
                                                />
                                                <Link to="/admin" className="btn btn-danger mx-2">Annuler</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Contact</h5>

                                                <Field
                                                    name="company_contact"
                                                    error={errors.company_contact}
                                                    value={setting.company_contact}
                                                    onChange={handleChange}
                                                    label="Téléphone"
                                                    placeholder="Téléphone"
                                                />

                                                <Field
                                                    name="company_email"
                                                    error={errors.company_email}
                                                    value={setting.company_email}
                                                    onChange={handleChange}
                                                    label="E-mail"
                                                    placeholder="E-mail"
                                                />
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Paramettres généraux</h5>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <Field
                                                            name="currency"
                                                            error={errors.currency}
                                                            value={setting.currency}
                                                            onChange={handleChange}
                                                            type="number"
                                                            label="Taux"
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="updateTicketPerm" className="text-success bold">
                                                            Permettre l'ajout de ticket ?
                                                        </label>
                                                        <CheckBoxField
                                                            name="updateTicketPerm"
                                                            id="updateTicketPerm"
                                                            checked={setting.updateTicketPerm ? "checked" : ""}
                                                            value={setting.updateTicketPerm}
                                                            label="Cocher ici !"
                                                            onChange={handleChex}
                                                            //checked={checked ? "checked" : ""}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="perm_date" className="text-success bold">
                                                            Pour quelle date
                                                        </label>
                                                        <Field
                                                            name="perm_date"
                                                            id="perm_date"
                                                            value={setting.perm_date}
                                                            onChange={handleChange}
                                                            type="date"
                                                            disabled={!setting.updateTicketPerm ? "disabled" : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row p-3">
                                                <div className="col-6">
                                                    <button
                                                        title="Cloturer ce bus ?"
                                                        onClick={() => {
                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                handleDelteDatas()
                                                            }
                                                        }}
                                                        className="btn btn-danger badge">
                                                        Supprimer les données de 3 mois antérieures  <i className="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </section>
        </>
    );
};

export default SettingIndex;
