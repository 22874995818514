import React, {useEffect, useState} from 'react';
import PrintComponent from "./printer";
import {toast} from "react-toastify";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import Field from "../../forms/Field";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import MyButton from "../../assets/MyButton";
import CheckBoxField from "../../forms/CheckBoxField";
import {Link} from "react-router-dom";
import AuthService from "../../../Services/auth/authAPI";
import TableRowsForFret from "./tableRowsForFret";


const NewVente = () => {

    const [showLoader, setShowLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [trajs, setTrajets] = useState([]);
    const [total, setTotal] = useState("");
    const [assign, setAssign] = useState({id : ""});
    const [dailyTickets, setDailyTickets] = useState([]);
    const [buses, setBuses] = useState([]);
    const [setting, setSetting] = useState([]);

    const [checked, setChecked] = useState(false);
    const [rowsData, setRowsData] = useState([]);

    const [ticket, setTicket] = useState({
        customer : "",phone : "", destination : "", assignment_id : "" , bonus : 0, lesColis : rowsData,
    });

    const [errors, setErrors] = useState({
        customer : "",phone : "", destination : "", assignment_id : ""
    });


    const fetchDailyAssignments = async () => {
        try{
            const data =  await VendeurAPI.getDirectAssignmentsBuses();
            setBuses(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des bus du jours !");
        }
    };
    const dailySales = async () => {
        try{
            const data =  await VendeurAPI.dailyDirectSales();
            setDailyTickets(data.buses);
            setTotal(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des ventes du jour !");
        }
    };
    const fetchSetting = async () => {
        try{
            const data =  await VendeurAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };
    const getTrajets = async () => {
        try{
            const data =  await VendeurAPI.getTrajets();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };

    const [pdVente, setPos] = useState([]);

    const fetchPos = async () => {
        try{
            const data =  await VendeurAPI.findPos();
            setPos(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement du point de vente !");
        }
    };

    useEffect(() => {
        fetchDailyAssignments();
        getTrajets();
        dailySales();
        fetchSetting();
        fetchPos();
    },[]);




    const handleChex = () => {
        setChecked(!checked);
        if (!checked === true) {
            setTicket({...ticket, bonus : 1});
        }else{
            setTicket({...ticket, bonus : 0});
        }

    };

    const  handleCloseBus = async () => {

        try{

            setShowLoader(true);
            await VendeurAPI.closedBus(assign);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Le bus a été cloturé avec succès");
            window.location.reload();
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {message} = response.data;
            toast.error(message);
        }
    };


    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setTicket({...ticket, [name] : value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await VendeurAPI.storeTickets(ticket);
            setTimeout(() => setShowLoader(false), 100);
            toast.success("Billet ajouté avec succès");
            window.location.reload();
        }catch({response}){
            const {violations} = response.data;
            setTimeout(() => setShowLoader(false), 100);

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });
                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire d'ajout de billet !");
            }


            if (response.data.error){
                setErrors({customer:["Un autre client avec ce meme nom existe dejà dans ce bus"]});
                toast.error(response.data.error);
            }
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 9999;

    //Filtrage des buses en fonction de la recherche
    const filteredTickets = dailyTickets.filter(

        b =>
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.to.toLowerCase().includes(search.toLowerCase()) ||
            //b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
            b.from.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedTickets = Pagination.getData(
        filteredTickets,
        currentPage,
        itemsPerPage
    );


    let counter2 = 1;


    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.amount);
        return tCDF
    }

    function calculColisSubTotal(data){
        let tCDF = 0;
        data.map((ticket) => ticket.fret_items.map((colis) => tCDF += colis.amount));
        return tCDF
    }

    function calculCDF(data) {
        let tCDF = 0;
        data.map((item) => tCDF += item.totalCDF);
        return tCDF
    }

    function calculUSD(data) {
        let tUSD = 0;
        data.map((item) => tUSD += item.totalUSD);
        return tUSD
    }

    function calculTotal(data) {
        let tCDF = 0;
        data.map((toto) => toto.tickets.map((ticket) => tCDF += ticket.amount));
        return tCDF
    }

    function calculTotalColis(data){
        let tCDF = 0;
        data.map((toto) => toto.tickets.map((ticket) => ticket.fret_items.map((colis) => tCDF += colis.amount)));
        return tCDF
    }

    function calculSpentTotalCDF(data) {
        let tCDF = 0;
        data.map((toto) => toto.bus_spents.map((spent) => tCDF += spent.totalCDF));
        return tCDF
    }

    function calculSpentTotalUSD(data) {
        let tUSD = 0;
        data.map((toto) => toto.bus_spents.map((spent) => tUSD += spent.totalUSD));
        return tUSD
    }

    const ticketsByDestination = (data) => {
        const destinations = data.tickets.map((key) => key.destination);
        const destinationBs = data.billets.map((key) => key.destination);
        let data_table = [];
        destinations.forEach((val) => {
            if (data_table[val]) {
                data_table[val] += 1;
            } else {
                data_table[val] = 1;
            }
        });

        destinationBs.forEach((val) => {
            if (data_table[val]) {
                data_table[val] += 1;
            } else {
                data_table[val] = 1;
            }
        });
        return data_table;
    };


    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const addTableRows = ()=>{

        const rowsInput={
            title:'', amount: ''
        };
        setRowsData([...rowsData, rowsInput])

    };
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
        setTicket({...ticket, lesColis : rows});
    };

    const handleForChange = (index, event) => {
        const { name, value } = event.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        setTicket({...ticket, lesColis : rowsData});
    };


    return <div className="container">
        <div className="row">
            <div className="col-md-5">
                <div className="card bg-dark-light">
                    <div className="card-body">
                        <div className="row">
                            <div className="col mt-1">
                                <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                    <img src="https://img.freepik.com/free-icon/user_318-159711.jpg" width="35" height="35" alt="John" className="shadow" />
                                    <span className="d-none d-md-block ps-1 pt-3">
                                        <h6 className="fw-bold text-dark    ">
                                            {me && (<>{me.user.name} <br/> {me.pos.pos_name}</>)}
                                        </h6>
                                    </span>
                                </a>
                            </div>
                            <h3 className="card-title text-primary col-md-4">BUS DISPONIBLES</h3>
                        </div>
                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-sm">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col" className="text-center">Place(s)</th>
                                    <th scope="col"><i className="bi bi-gear-fill"> options</i></th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {buses.map((bus) => (
                                    <tr key={bus.id}>
                                        <th scope="row">{counter2++}</th>
                                        <td><b>{bus.bus_numberplate}</b></td>
                                        {bus.shared === 1 && <td className="text-center">{ bus.bus_number_of_seats - (bus.shared_ticket + bus.tickets.length + bus.billets.length)}</td>}
                                        {bus.shared === 2 && <td className="text-center">{ bus.bus_number_of_seats - (bus.shared_ticket + bus.tickets.length + bus.billets.length)}</td>}
                                        {bus.shared === 0 && <td className="text-center">{ bus.bus_number_of_seats - bus.tickets.length - bus.billets.length}</td>}
                                        <td>
                                            <div className="btn-group btn-sm">
                                                <button
                                                    title="Cloturer ce bus ?"
                                                    onClick={() => {
                                                        if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                            setAssign(bus.id);
                                                            handleCloseBus(bus.id)
                                                        }
                                                    }}
                                                    className="btn btn-danger badge">
                                                    Cloturer <i className="bi bi-x-circle"></i>
                                                </button>
                                                {bus.shared === 0 && <Link to={`/customer/share/bus/${bus.id}`} className="btn btn-primary badge">
                                                    Partager ce bus  <i className="bi bi-share"></i>
                                                </Link>}

                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                                </tbody>}
                            </table>
                        </div>
                    </div>
                    {loading && <TableLoader width={300} height={350}/>}
                </div>
                <div className="card">
                    <form onSubmit={handleSubmit} className="needs-validation">
                        <div className="card-body">
                            <h3 className="card-title text-success">Ajouter un client</h3>

                            <label htmlFor="assignment_id" className="form-label">Bus</label>
                            {!loading &&
                                <select
                                    className={"form-select-lg form-control mb-2 " + ( errors.assignment_id && "is-invalid")}
                                    //size={(buses.length)}
                                    name="assignment_id"
                                    id="assignment_id"
                                    value={ticket.assignment_id}
                                    onChange={handleChange}
                                >

                                    <option value="">Choisir...</option>
                                    {buses && buses.map((bus) => (
                                        <option style={{fontWeight:"bold"}} key={bus.id} value={bus.id}>
                                            {bus.bus_numberplate} - Vers : {bus.to}</option>
                                    ))
                                    }
                                </select>
                            }
                            {errors.assignment_id && <div className="invalid-feedback mb-4">{errors.assignment_id}</div>}


                            <label htmlFor="destination" className="form-label">Destination</label>
                            {!loading &&
                            // <select
                            //      //className={"form-select mb-3 " + ( errors.destination && "is-invalid")}
                            //      className={"form-select-lg mb-1 form-control " + ( errors.destination && "is-invalid")}
                            //      //size={trajs.length}
                            //      name="destination"
                            //      id="destination"
                            //      value={ticket.destination}
                            //      onChange={handleChange}
                            // >
                            //     <option value="" selected>Choisir...</option>
                            //     {trajs && trajs.map(traj =>
                            //         <option
                            //             style={{fontWeight:"bold"}}
                            //                 key={traj.id} value={traj.id}>{traj.to}</option>)
                            //     }
                            // </select>
                                <>
                                    {trajs && trajs.map(traj =>
                                            <div className="form-check" key={traj.id}>
                                                <input onChange={handleChange} value={traj.id} className="form-check-input" type="radio" name="destination" id={traj.to} />
                                                <label className="form-check-label" htmlFor={traj.to}>
                                                    <b>{traj.to} - </b> {traj.price} CDF
                                                </label>
                                            </div>
                                        )}
                                </>
                            }
                            {errors.destination && <div className="invalid-feedback mb-4">{errors.destination}</div>}

                            <div className="row">
                                <div className="col-md-8">
                                    <Field
                                        name="customer"
                                        error={errors.customer}
                                        value={ticket.customer}
                                        onChange={handleChange}
                                        label="Nom du client"
                                        placeholder="Client..."
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="bonus" className="text-success bold">Bonus KKS ?</label>
                                    <CheckBoxField
                                        name="bonus"
                                        id="bonus"
                                        value={ticket.bonus}
                                        label="Cocher ici !"
                                        onChange={handleChex}
                                        //checked={checked ? "checked" : ""}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <Field
                                        name="phone"
                                        error={errors.phone}
                                        value={ticket.phone}
                                        onChange={handleChange}
                                        label="Téléphone du client"
                                        placeholder="+243..."
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <hr/>

                            <div className="row">
                                <h4 className="fw-bold" style={{textDecoration:"underline"}}>Colis accompagnés</h4>
                                <div className="col-lg-12">
                                    <a className="btn btn-sm btn-primary" style={{float:"inline-end"}} onClick={addTableRows} >
                                        <i className="bi bi-plus-circle"></i> ajouter un colis
                                    </a>
                                </div>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Qté</th>
                                            <th>Prix</th>
                                        </tr>

                                        </thead>
                                        <tbody>

                                        <TableRowsForFret rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleForChange} />

                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <MyButton
                                text="Enregistrer"
                                onSubmit={handleSubmit}
                                loading={showLoader}
                                disabled={showLoader}
                            />
                        </div>
                    </form>
                </div>

            </div>
            <div className="col-md-7">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">
                            Total Bus : <b className="text-primary">{paginatedTickets ? paginatedTickets.length : 0}</b>| Ticket
                            : {total && (<><b className="text-success">{calculTotal(paginatedTickets)}</b> CDF</>)} |
                            Colis :  <b className="text-success fw-bold">{calculTotalColis(paginatedTickets) } CDF</b> <br/>
                            Depenses : <b className="text-danger fw-bold">{calculSpentTotalCDF(paginatedTickets) } CDF + { calculSpentTotalUSD(paginatedTickets)} $</b>
                        </h2>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mt-1">
                                    <label htmlFor="ByAll" className="text-dark">
                                        Recherche par Bus
                                    </label>
                                    <input type="text"
                                           onChange={handleSearch}
                                           value={search}
                                           name="ByAll"
                                           id="ByAll"
                                           className="form-control"
                                           placeholder="Rechercher ..."
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        {!loading && <ol className="list-group list-group-numbered">
                            {paginatedTickets.map((bus) => {
                                let counti = 1;
                                return (<div key={bus.id}>
                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <i className="bi bi-car-front"></i> <span className="text-success fw-bold">{bus.bus_numberplate}</span> ,
                                                    {bus.to}. H : {bus.bus_hour}
                                                    <br/><i className="bi bi-person-gear"></i> <b>{bus.driver_name}</b> ,
                                                    <i className="bi bi-person-fill-up"></i> <b>{bus.manager_name}</b><br/>
                                                    <span className="">
                                                    <b className="text-success">Tickets : {calculSubTotal(bus.tickets)} Fc </b> +
                                                        <b className="text-info"> Colis : {calculColisSubTotal(bus.tickets)} Fc </b> -
                                                </span>
                                                <span>
                                                    <b className="text-danger"> ({calculCDF(bus.bus_spents)} Fc + {calculUSD(bus.bus_spents)}) $</b> <br/> =
                                                    <b className="text-success"> Net : {(calculSubTotal(bus.tickets) + calculColisSubTotal(bus.tickets)) - (calculCDF(bus.bus_spents) + ((calculUSD(bus.bus_spents) * setting.currency)))} Fc</b>
                                                </span>
                                                <div className="">
                                                    {ticketsByDestination(bus).Lubumbashi && (<span className="badge bg-primary">
                                                Lubumbashi : {ticketsByDestination(bus).Lubumbashi}</span>)}

                                                    {ticketsByDestination(bus).Kolwezi && (<span className="badge bg-success mx-1">
                                                Kolwezi : {ticketsByDestination(bus).Kolwezi}</span>)}

                                                    {ticketsByDestination(bus).Likasi && (<span className="badge bg-info mx-1">
                                                Likasi : {ticketsByDestination(bus).Likasi}</span>)}

                                                    {ticketsByDestination(bus).Fungurume && (<span className="badge bg-dark mx-1">
                                                Fungurume : {ticketsByDestination(bus).Fungurume}</span>)}

                                                    {ticketsByDestination(bus).Kasumbalesa && (<span className="badge bg-danger mx-1">
                                                Kasumbalesa : {ticketsByDestination(bus).Kasumbalesa}</span>)}

                                                    {ticketsByDestination(bus).Sakania && (<span className="badge bg-primary mx-1">
                                                Sakania : {ticketsByDestination(bus).Sakania}</span>)}

                                                    {ticketsByDestination(bus).Kamoa && (<span className="badge bg-primary mx-1">
                                                Kamoa : {ticketsByDestination(bus).Kamoa}</span>)}
                                                </div>
                                            </div>
                                            <ul>
                                                {bus.shared === 0 &&<li><span><b>{bus.tickets.length + bus.billets.length} <i className="bi bi-people"></i></b> / {bus.bus_number_of_seats}</span></li>}

                                                {bus.shared === 2 && <>
                                                    <li><span><b>{bus.tickets.length + bus.billets.length} <i className="bi bi-people"></i></b></span></li>
                                                <li className="text-success">{bus.shared_ticket} vendus</li>
                                                    <li><span><b>Total : {bus.tickets.length + bus.shared_ticket + bus.billets.length} <i className="bi bi-people"></i></b> / {bus.bus_number_of_seats}</span></li>
                                                </>}

                                                {bus.shared === 1 && <>
                                                    <li><span><b>{bus.tickets.length + bus.billets.length} <i className="bi bi-people"></i></b></span></li>
                                                    <li className="text-danger">{bus.shared_ticket} vendus</li>
                                                    <li><span><b>Total : {bus.tickets.length + bus.shared_ticket + bus.billets.length} <i className="bi bi-people"></i></b> / {bus.bus_number_of_seats}</span></li>
                                                </>}
                                            </ul>


                                            <a className="badge bg-success mx-2" data-bs-toggle="collapse"
                                               href={"#tickets"+bus.id} role="button" aria-expanded="false"
                                               aria-controls={"tickets"+bus.id}>Les billets <i className="bi bi-eye"></i> </a>
                                            {/*<button*/}
                                                {/*onClick={() => {*/}
                                                    {/*if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {*/}
                                                        {/*handlePrintAll(bus)*/}
                                                    {/*}*/}
                                                {/*}}*/}
                                                {/*className="badge bg-info">*/}
                                                {/*Imprimer <i className="bi bi-printer"></i>*/}
                                            {/*</button>*/}
                                        </li>

                                        <div className="collapse multi-collapse" id={"tickets"+bus.id}>
                                            <div className="card card-body">
                                                <table className="table table-hover table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        {/*<th scope="col">Numero fret_items</th>*/}
                                                        <th scope="col">Client</th>
                                                        <th scope="col">Destination</th>
                                                        <th scope="col">Colis</th>
                                                        <th scope="col">Bonus</th>
                                                        <th scope="col">Option</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {bus.tickets
                                                        .sort((a, b) => b.id - a.id)
                                                        .map((ticket) => (
                                                            <tr key={ticket.id}>
                                                                <td>{counti++}</td>
                                                                <td>
                                                                    <b className="text-dark">{ticket.customer}</b>
                                                                    <br/>
                                                                    <small className="text-success">{ticket.bus_number}</small>
                                                                </td>
                                                                <td>{ticket.destination} <br/>
                                                                    <span className="text-success">
                                                                        {ticket.amount} CDF
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ticket.fret_items &&
                                                                        <>
                                                                            <ul>
                                                                                {ticket.fret_items.map((coli) =>
                                                                                    <li style={{fontSize:"14px"}}>
                                                                                        {coli.weight} {coli.name} - {coli.amount} Fc
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    }
                                                                </td>
                                                                <td>{ticket.bonus === 1 ? (<b className="badge text-bg-success">Oui</b>)
                                                                    :(<b className="badge text-bg-dark">Non</b>)}
                                                                </td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        {!ticket.printed && <button className="btn btn-primary">
                                                                            <PrintComponent plaque={bus.bus_numberplate} hour={bus.bus_hour}
                                                                                            setting={setting} ticket={ticket} pos={pdVente}
                                                                            />
                                                                        </button>}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))
                                                    }

                                                    {bus.billets
                                                        .sort((a, b) => b.id - a.id)
                                                        .map((billet) => (
                                                            <tr key={billet.id}>
                                                                <td>{counti++}</td>
                                                                <td>
                                                                    <b className="text-dark">{billet.customer}</b>
                                                                    <br/>
                                                                    <small className="text-success">{billet.bus_number}</small>
                                                                </td>
                                                                <td>{billet.destination} <br/>
                                                                    <span className="text-success">
                                                                        {billet.amount} CDF
                                                                    </span>
                                                                </td>
                                                                <td></td>
                                                                <td>{billet.bonus === 1 ? (<b className="badge text-bg-success">Oui</b>)
                                                                    :(<b className="badge text-bg-dark">Non</b>)}
                                                                </td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        {!billet.printed && <button className="btn btn-primary">
                                                                            <PrintComponent type="billet" plaque={bus.bus_numberplate} hour={bus.bus_hour}
                                                                                            setting={setting} ticket={billet} pos={pdVente}
                                                                            />
                                                                        </button>}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </ol>}

                        {loading && <TableLoader width={300} height={350} />}

                        {itemsPerPage < filteredTickets.length &&
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            length={filteredTickets.length}
                            onPageChange={handleChangeCurrentPage}
                        />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default NewVente;
