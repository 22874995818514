import axios from "axios";
import { MANIFEST_BUSES_API, MANIFEST_SETTING_API, MANIFEST_POS_API} from "../../config";
import Cache from "../cache";

async function findSetting() {
    // const cachedCustomSetting = await Cache.get("CustomSetting");
    // if(cachedCustomSetting) return cachedCustomSetting;
    return await axios
        .get(MANIFEST_SETTING_API)
        .then(response => {
            //const setting = response.data.setting;
            //Cache.set("CustomSetting", setting);
            return response.data.setting;
        });
}



async function findPos() {
    // const cachedCustomPos = await Cache.get("CustomPos");
    // if(cachedCustomPos) return cachedCustomPos;
    return await axios
        .get(MANIFEST_POS_API + "/getPos")
        .then(response => {
            //const pos = response.data.pos;
            //Cache.set("CustomPos", pos);
            return response.data.pos;
        });
}


async function findOneFret(id){
    // const cachedCustomerTickets = await Cache.get("CustomerTickets." + id);
    // if(cachedCustomerTickets) return cachedCustomerTickets;
    return await axios.get(MANIFEST_POS_API + "/frets/" + id)
        .then(response => {
            //const invoice = response.data;
            //Cache.set("CustomerTickets." + id, invoice);
            return response.data;
        });
}

async function getSheduledAssignmentsBuses() {
    return await axios
        .get(MANIFEST_BUSES_API + "/getSheduledAssignmentsBuses")
        .then(response => {
            //const assignments = response.data.assignments;
            //Cache.set("CustomerAssignments", assignments);
            return response.data.assignments;
        });
}


async function getDirectAssignmentsBuses() {
    // const cachedCustomerAssignments = await Cache.get("CustomerAssignments");
    // if(cachedCustomerAssignments) return cachedCustomerAssignments;
    return await axios
        .get(MANIFEST_BUSES_API + "/getDirectAssignmentsBuses")
        .then(response => {
            //const assignments = response.data.assignments;
            //Cache.set("CustomerAssignments", assignments);
            return response.data.assignments;
        });
}


async function getTransitAssignmentsBuses() {
    // const cachedCustomerAssignments = await Cache.get("CustomerAssignments");
    // if(cachedCustomerAssignments) return cachedCustomerAssignments;
    return await axios
        .get(MANIFEST_BUSES_API + "/getTransitAssignmentsBuses")
        .then(response => {
            //const assignments = response.data.assignments;
            //Cache.set("CustomerAssignments", assignments);
            return response.data.assignments;
        });
}


async function getTrajets() {
    // const cachedCustomerTrajets = await Cache.get("CustomerTrajets");
    // if(cachedCustomerTrajets) return cachedCustomerTrajets;
    return await axios
        .get(MANIFEST_POS_API + "/getTrajets")
        .then(response => {
            //const trajets = response.data.trajets;
            // Cache.set("CustomerTrajets", trajets);
            return response.data.trajets;
        });
}


async function dailyFrets() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyManifestSales")
        .then(response => {
            //const tickets = response.data.frets;
            return response.data;
        });
}

async function allWeeklyFretByBus(){
    return await axios
        .get(MANIFEST_POS_API + "/allWeeklyManifestSales")
        .then(response => {
            //const tickets = response.data.frets;
            return response.data;
        });
}


async function allFretByBus() {
    return await axios
        .get(MANIFEST_POS_API + "/allManifestSales")
        .then(response => {
            //const tickets = response.data.frets;
            return response.data;
        });
}

async function dailyReceivedFrets() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyReceivedManifest")
        .then(response => {
            //const tickets = response.data.frets;
            return response.data;
        });
}

async function dailyFretsForAmbarquement() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyFretsForAmbarquement")
        .then(response => {
            //const tickets = response.data.frets;
            //console.log(response.data);
            return response.data;
        });
}

async function fretsDeliverds() {
    return await axios
        .get(MANIFEST_POS_API + "/fretsDeliverds")
        .then(response => {
            //const tickets = response.data.frets;
            //console.log(response.data);
            return response.data;
        });
}

async function dailyFretsForReception() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyFretsForReception")
        .then(response => {
            //const tickets = response.data.frets;
            //console.log(response.data);
            return response.data;
        });
}


async function dailySales() {
    return await axios
        .get(MANIFEST_POS_API + "/dailySales")
        .then(response => {
            //const tickets = response.data.tickets;
            //console.log(response.data);
            return response.data;
        });
}

async function sheduledSales() {
    return await axios
        .get(MANIFEST_POS_API + "/dailySheduledSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function dailyDirectSalesForEmbarquement() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyDirectSalesForEmbarquement")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function sentFrets() {
    return await axios
        .get(MANIFEST_POS_API + "/sentFrets")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function dailyDirectSales() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyDirectSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function dailyTransitsSales() {
    return await axios
        .get(MANIFEST_POS_API + "/dailyTransitsSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allSales() {
    return await axios
        .get(MANIFEST_POS_API + "/allSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allFrets() {
    return await axios
        .get(MANIFEST_POS_API + "/allFrets")
        .then(response => {
            const tickets = response.data.tickets;
            return response.data;
        });
}



function addFretToBus(item, bus, quant) {
    const fretItem = {itemId : item, busId: bus, quantity: quant};
    return axios
        .post(MANIFEST_BUSES_API + "/addFretToBus", fretItem)
        .then(async response => {
            return response;
        });
}





function deliverFret(item) {
    const fretItem = {itemId : item};
    return axios
        .post(MANIFEST_BUSES_API + "/deliverFret", fretItem)
        .then(async response => {
            return response;
        });
}


export default {
    addFretToBus, findSetting,
    findPos, getDirectAssignmentsBuses,
    getSheduledAssignmentsBuses, getTransitAssignmentsBuses,
    getTrajets, dailySales,
    dailyDirectSales, dailyDirectSalesForEmbarquement,
    dailyTransitsSales, allSales,
    allFretByBus, allWeeklyFretByBus, dailyFrets,sentFrets,
    dailyFretsForAmbarquement, allFrets,fretsDeliverds,deliverFret,
    sheduledSales,dailyReceivedFrets,dailyFretsForReception
};