import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../../Services/auth/authAPI";
import { ReactComponent as Loader } from '../../assets/loader.svg'
import BielleterieAPI from "../../../apis/adminApi/bielleterieAPI";
import {toast} from "react-toastify";

const Admin_header = () => {

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const [showLoader, setShowLoader] = useState(false);

    const [billets, setBillets] = useState();
    const [billetsj, setBilletsj] = useState();
    const [billetsm, setBilletsm] = useState();

    const handleLogout = () => {

        setShowLoader(true);
        setTimeout(() => setShowLoader(false), 1000);
        AuthService.logout();
        navigate("/login")
    };

    const fetchBilletAdmin = async () => {
        try{
            const data =  await BielleterieAPI.fetchBilletAdmin();
            setBillets(data.billets);
            setBilletsj(data.billetsj);
            setBilletsm(data.billetsm);
        }catch(error){
            toast.error("Erreur lors du chargement des billet !");
        }
    };

    useEffect(() => {
        fetchBilletAdmin();
    },[]);

    function calculColisBilletTotal(data){
        let tCDF = 0;
        data.map((billets) => tCDF += billets.amount);
        return tCDF
    }


    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <Link to="/admin" className="logo d-flex align-items-center">
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" />
                    <span className="d-none d-lg-block">KKS Admin</span>
                </Link>
                <i className="bi bi-list toggle-sidebar-btn"></i>
            </div>

            <div className="search-bar">
                {/*<form className="search-form d-flex align-items-center" method="POST" action="#">*/}
                    {/*<input type="text" name="query" placeholder="Search" title="Enter search keyword" />*/}
                    {/*<button type="submit" title="Search"><i className="bi bi-search"></i></button>*/}
                {/*</form>*/}
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>

                    {/*<li className="nav-item dropdown">*/}

                        {/*<a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">*/}
                            {/*<i className="bi bi-bell"></i>*/}
                            {/*<span className="badge bg-primary badge-number">4</span>*/}
                        {/*</a>*/}

                        {/*<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">*/}
                            {/*<li className="dropdown-header">*/}
                                {/*You have 4 new notifications*/}
                                {/*<a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-exclamation-circle text-warning"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Lorem Ipsum</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>30 min. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-x-circle text-danger"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Atque rerum nesciunt</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>1 hr. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-check-circle text-success"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Sit rerum fuga</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>2 hrs. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-info-circle text-primary"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Dicta reprehenderit</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>4 hrs. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}
                            {/*<li className="dropdown-footer">*/}
                                {/*<a href="#">Show all notifications</a>*/}
                            {/*</li>*/}

                        {/*</ul>*/}

                    {/*</li>*/}

                    {/*<li className="nav-item dropdown">*/}

                        {/*<a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">*/}
                            {/*<i className="bi bi-chat-left-text"></i>*/}
                            {/*<span className="badge bg-success badge-number">3</span>*/}
                        {/*</a>*/}

                        {/*<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">*/}
                            {/*<li className="dropdown-header">*/}
                                {/*You have 3 new messages*/}
                                {/*<a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="message-item">*/}
                                {/*<a href="#">*/}
                                    {/*<img src={process.env.PUBLIC_URL + '/assets/img/messages-1.jpg'} alt="" className="rounded-circle" />*/}
                                    {/*<div>*/}
                                        {/*<h4>Maria Hudson</h4>*/}
                                        {/*<p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>*/}
                                        {/*<p>4 hrs. ago</p>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="message-item">*/}
                                {/*<a href="#">*/}
                                    {/*<img src={process.env.PUBLIC_URL + '/assets/img/messages-2.jpg'} alt="" className="rounded-circle" />*/}
                                    {/*<div>*/}
                                        {/*<h4>Anna Nelson</h4>*/}
                                        {/*<p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>*/}
                                        {/*<p>6 hrs. ago</p>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="message-item">*/}
                                {/*<a href="#">*/}
                                    {/*<img src={process.env.PUBLIC_URL + '/assets/img/messages-3.jpg'} alt="" className="rounded-circle" />*/}
                                    {/*<div>*/}
                                        {/*<h4>David Muldon</h4>*/}
                                        {/*<p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>*/}
                                        {/*<p>8 hrs. ago</p>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="dropdown-footer">*/}
                                {/*<a href="#">Show all messages</a>*/}
                            {/*</li>*/}

                        {/*</ul>*/}

                    {/*</li>*/}

                    <li className="nav-item dropdown pe-3">

                        { me.user.id === 1 ? billets && <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <img title="" src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                                 width="32" height="32" alt="John"
                                 className="shadow" />
                            <span className="d-none d-md-block dropdown-toggle ps-2">
                                {me && (<>{me.user.name}</>)}
                            </span>
                        </a> : <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <img src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                                 width="32" height="32" alt="John"
                                 className="shadow" />
                            <span className="d-none d-md-block dropdown-toggle ps-2">
                                {me && (<>{me.user.name}</>)}
                            </span>
                        </a>}

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{me &&  (<>{me.user.name}</>)}</h6>
                                <span>Administrateur</span>
                            </li>
                            { me.user.id === 1 ? billets &&
                                (<><li><hr className="dropdown-divider" /></li>
                                    <li>G : {calculColisBilletTotal(billets)}</li></>) : (<></>)
                            }

                            { me.user.id === 1 ? billetsj &&
                                (<><li><hr className="dropdown-divider" /></li>
                                    <li>J : {calculColisBilletTotal(billetsj)}</li></>) : (<></>)
                            }

                            { me.user.id === 1 ? billetsm &&
                                (<><li><hr className="dropdown-divider" /></li>
                                    <li>M : {calculColisBilletTotal(billetsm)}</li></>) : (<></>)
                            }

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="users-profile.html">*/}
                                    {/*<i className="bi bi-person"></i>*/}
                                    {/*<span>My Profile</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="users-profile.html">*/}
                                    {/*<i className="bi bi-gear"></i>*/}
                                    {/*<span>Account Settings</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="pages-faq.html">*/}
                                    {/*<i className="bi bi-question-circle"></i>*/}
                                    {/*<span>Need Help?</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            <li>
                                <button className="dropdown-item d-flex align-items-center text-danger" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Se deconnecter</span>
                                </button>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>

        </header>
    );
};

export default Admin_header;
