import React, { useEffect, useState } from 'react';
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import Moment from "moment/moment";
import CustomAPI from "../../../apis/customerApi/VendeurAPI";

const AllSpents = () => {

    const [allSpents, setDailySpents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");

    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");
    const [billets, setBillets] = useState();

    const getAllSpents = async () => {
        try{
            const data =  await VendeurAPI.allSpents();
            setDailySpents(data.spents);
            setOutPutData(data.spents);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const fetchBillets = async () => {
        try{
            const data =  await CustomAPI.fetchBillet();
            setBillets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des billet !");
        }
    };

    useEffect(() => {
        getAllSpents();
        fetchBillets();
    },[]);


    function calculColisBilletTotal(){
        let tCDF = 0;
        var data = billets;
        data.map((bil) => tCDF += bil.amount);
        return tCDF
    }


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 500;

    //Filtrage des buses en fonction de la recherche

    const filteredSpents = vivo.filter(
        b =>
            b.name.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedSpents = Pagination.getData(
        filteredSpents,
        currentPage,
        itemsPerPage
    );


    let counter = 1;


    const tooltip = (data) => (
        <Tooltip className="" id="tooltip">
            <ul className="list-group">
                {data && data.map((sp, index) => (<li key={index}>{sp.title} - {sp.amount} {sp.currency}</li>))}
            </ul>
        </Tooltip>
    );

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allSpents.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allSpents.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allSpents.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };


    let tUSD = 0;
    let tCDF = 0;

    function calculTotal(data) {
        let tUSD = 0;
        let tCDF = 0;
        data.map((spent) => tCDF += spent.totalCDF);
        data.map((spent) => tUSD += spent.totalUSD);
        return {tCDF, tUSD}
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="card-title">
                                        Mes depenses - Total : <b className="text-primary"> </b>
                                    </h2>
                                </div>
                                <div className="col-md-6">
                                    <h2 className="card-title">Total Autres depenses du jour</h2>
                                    <h3><b>{calculTotal(paginatedSpents).tUSD} USD - {calculTotal(paginatedSpents).tCDF} CDF</b></h3>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Utilisateur</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Total USD</th>
                                    <th scope="col">Total CDF</th>
                                    <th scope="col">Options</th>
                                </tr>
                                </thead>
                                {!loading && <tbody>
                                {paginatedSpents.map((spent) => (
                                    <tr key={spent.id}>
                                        <th scope="row">{counter++}</th>
                                        <td>{spent.name}</td>
                                        <td>Le {Moment(spent.created_at).format("DD/MM/YYYY")}</td>
                                        <td>{spent.totalUSD} $</td>
                                        <td>{spent.totalCDF} FC</td>
                                        <td>
                                            <div className="btn-group">
                                                <ul className="list-group">
                                                    {spent.spent_items && spent.spent_items.map((sp, index) =>
                                                        (<li key={index}>{sp.title} - {sp.amount} {sp.currency}
                                                        </li>))}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }

                                </tbody>}
                            </table>

                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredSpents.length &&
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredSpents.length}
                                onPageChange={handleChangeCurrentPage}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllSpents;
